const init = {
    
    prefCampuses: null,
    prefCourses: null,
    prefMajors: null,
    activeTermID: null,



}

const admissionProgram = (state = init, action) => {
    switch (action.type) {
        case 'PREF_CAMPUSES_SET':
            return {
                ...state,
                prefCampuses: action.payload
            }
        case 'PREF_COURSES_SET':
            return {
                ...state,
                prefCourses: action.payload
            }
        case 'PREF_MAJORS_SET':
            return {
                ...state,
                prefMajors: action.payload
            }
        case 'ACTIVE_TERM_ID_SET':
            return {
                ...state,
                activeTermID: action.payload
            }
        default:
            return state
    }

}


export default admissionProgram;