import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { 
    Table,
    Grid,
    TextField,
    Button,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import {
    getTestingCenters,
    getActiveTermID,
    getTestingDates,
    getTestingTimes,
    getTestingRooms,
    saveApplicantTestSchedule,
    resetTestSchedule
}from './../../redux/actions/admissionTest';

const useStyles = makeStyles({

})

const TestScheduleSelector = (props) => {

    let history = useHistory();

    const admissionTest = useSelector(state => state.admissionTest);
    const ACTIVE_TERM_ID = admissionTest.activeTermID;

    const initTestScheduleDetails = {
        testCenterID: -1,
        testCenterObj: null,
        testDateID: null,
        testDateObj: null,
        testTimeID: null,
        testTimeObj: null,
        testRoomID: null,
        testRoomObj: null
    }
    // const formInputRef = useRef();

    const [testScheduleDetails, setTestScheduleDetails] = useState(initTestScheduleDetails);
    const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
    const [openConfirmSubmission, setOpenConfirmSubmission] = useState(false);

    const saveApplicantTestScheduleHandler = () => {
        const {testCenterObj, testDateObj, testTimeObj, testRoomObj} = testScheduleDetails;
        const appNo = props.appNo ? props.appNo : ''; //'2022-0000000001'       //props.appNo

        const applicantTestScheduleDetails = {
            appNo: appNo,
            testCenterID: testCenterObj.id,
            testDateID: testDateObj.testDateID,
            testTimeID: testTimeObj.testTimeID,
            testSessionID: testTimeObj.testSessionID,
            testRoomID: testRoomObj.testRoomID,
            termID: ACTIVE_TERM_ID
        }

        // const onSuccess = () => {
        //     setTestScheduleDetails(initTestScheduleDetails);
        //     alert(`Submission Successful`);
        // }

        const onFailure = () => {
            alert(`Submission Failed`);
        }

        saveApplicantTestSchedule(applicantTestScheduleDetails, props.onSubmitTestScheduleSuccess, props.onSubmitTestScheduleFailed );
        
    }

    const PRINT_STATE = () => {
        console.log(`ADMISSION_TEST_USEEFFECT: ${JSON.stringify(admissionTest)}`);
        console.log(`TEST_SCHEDULE_DETAILS_USEEFFECT: ${JSON.stringify(testScheduleDetails)}`);
    }


    // FUNCTION: Handles changes in the test scheduling input fields
    const changeHandler = (prop, value) => {

        console.log(`prop: ${prop}, value: ${value}`);

        setTestScheduleDetails({
            ...testScheduleDetails,
            [prop]: value
        })

    }


    // HOOK: Calls relevant functions on first page load
    useEffect( () => {
        getTestingCenters();
        getActiveTermID();

        if(admissionTest.testingCenters && props.appCampusID){
            const defaultTestCenterObj = admissionTest.testingCenters.find( (testingCenter) => testingCenter.campusID === props.appCampusID);
            setTestScheduleDetails({
                ...testScheduleDetails,
                testCenterObj: defaultTestCenterObj
            })
        }  

    }, []);

    // HOOK: Sets default test center based on the applicant's course campus id
    useEffect( () => {
        if(admissionTest.testingCenters && props.appCampusID){
            const defaultTestCenterObj = admissionTest.testingCenters.find( (testingCenter) => testingCenter.campusID === props.appCampusID);
            setTestScheduleDetails({
                ...testScheduleDetails,
                testCenterObj: defaultTestCenterObj
            })
        }  
        
    }, [admissionTest.testingCenters])

    // HOOK: Calls getTestingDates() action when the selected testCenterID is changed
    useEffect( () => { 
        if(admissionTest.activeTermID){
            getTestingDates(
                {
                    termID: admissionTest.activeTermID, 
                    testCenterID: testScheduleDetails.testCenterObj ? testScheduleDetails.testCenterObj.id : -1              
                }
            );
        }
        changeHandler('testDateObj', null);
        PRINT_STATE();
    }, [testScheduleDetails.testCenterObj])

    // HOOK: Calls getTestingTimes() action when the selected testDateID is changed
    useEffect( () => { 
        if(admissionTest.activeTermID){
            getTestingTimes(
                {
                    termID: admissionTest.activeTermID, 
                    testCenterID: testScheduleDetails.testCenterObj ? testScheduleDetails.testCenterObj.id : -1,
                    testDateID: testScheduleDetails.testDateObj ? testScheduleDetails.testDateObj.testDateID : -1,
                }
            );
        } 
        changeHandler('testTimeObj', null);
        PRINT_STATE();
    }, [testScheduleDetails.testDateObj])

    // HOOK: Calls getTestingRooms() action when the selected testTimeID is changed
    useEffect( () => { 
        if(admissionTest.activeTermID){
            getTestingRooms(
                {
                    termID: admissionTest.activeTermID, 
                    testCenterID: testScheduleDetails.testCenterObj ? testScheduleDetails.testCenterObj.id : -1,
                    testDateID: testScheduleDetails.testDateObj ? testScheduleDetails.testDateObj.testDateID : -1,
                    testTimeID: testScheduleDetails.testTimeObj ? testScheduleDetails.testTimeObj.testTimeID : -1,
                }
            );
        } 
        changeHandler('testRoomObj', null);
        PRINT_STATE();
    }, [testScheduleDetails.testTimeObj])
    
    
    return (
        <div>
            <Grid item container lg={12} sm={12} style={{width:'80%',margin:'0 auto', marginTop: "30x"}}>

                <Typography style={{fontSize:'1.15rem',fontWeight:'bold'}}>
                    SET THE TEST SCHEDULE
                </Typography>
                {/* Test Center Input */}
                <Grid item lg={12} sm={12} style={{paddingTop:'10px'}}>
                    <Autocomplete 
                        name="testCenterID"
                        value={testScheduleDetails.testCenterObj}
                        // inputValue={testScheduleDetails.testCenterObj.testCenterName}
                        options={admissionTest.testingCenters}
                        getOptionLabel={(option) => option.testCenterName}
                        onChange={(e, selectedOption) => { 
                            // changeHandler("testCenterID", selectedOption ? selectedOption.id : null); 
                            changeHandler("testCenterObj", selectedOption ? selectedOption : null); 
                        } }
                        fullWidth
                        renderInput={(params) => 
                            <TextField {...params}
                                variant="outlined" 
                                label="Testing Center"
                                required
                            />
                        }
                    />
                </Grid>
       
                {/* Test Date Input */}
                <Grid item lg={12} sm={12} style={{paddingTop:'10px'}}>
                    <Autocomplete 
                        name="testDateID"
                        value={testScheduleDetails.testDateObj}
                        // inputValue={testScheduleDetails.testDateObj.testDate}
                        options={admissionTest.testingDates ? admissionTest.testingDates : [] }
                        getOptionLabel={(option) => option.testDate}
                        getOptionDisabled={(option) => option.isFull === true}
                        onChange={(e, selectedOption) => { 
                            // changeHandler("testDateID", selectedOption ? selectedOption.testDateID : null); 
                            changeHandler("testDateObj", selectedOption ? selectedOption : null) ;
                        } }
                        fullWidth
                        renderInput={(params) => 
                            <TextField {...params}
                                variant="outlined" 
                                label="Exam Date"
                                required
                            />
                        }
                    />
                </Grid>
                    
                {/* Test Time Input */}
                <Grid item lg={12} sm={12} style={{paddingTop:'10px'}}>
                    <Autocomplete 
                        name="testTimeID"
                        value={testScheduleDetails.testTimeObj}
                        // inputValue={testScheduleDetails.testTimeObj.testTime}
                        options={ admissionTest.testingTimes ? admissionTest.testingTimes : []}
                        getOptionLabel={(option) => option.testTime}
                        getOptionDisabled={(option) => option.isFull === true}
                        onChange={(e, selectedOption) => { 
                            // changeHandler("testTimeID", selectedOption ? selectedOption.testTimeID : null)  
                            changeHandler("testTimeObj", selectedOption ? selectedOption : null)  
                        } }
                        fullWidth
                        renderInput={(params) => 
                            <TextField {...params}
                                variant="outlined" 
                                label="Time of Exam"
                                required
                            />
                        }
                    />
                </Grid>
                
                {/* Test Room Input */}
                <Grid item lg={12} sm={12} style={{paddingTop:'10px'}}>
                    <Autocomplete 
                        name="testRoomID"
                        value={testScheduleDetails.testRoomObj}
                        // inputValue={testScheduleDetails.testRoomObj.testRoomName}
                        options={ admissionTest.testingRooms ? admissionTest.testingRooms : []}
                        getOptionLabel={(option) => option.testRoomName}
                        getOptionDisabled={(option) => option.isFull === true}
                        onChange={(e, selectedOption) => { 
                            // changeHandler("testRoomID", selectedOption ? selectedOption.testRoomID : null)
                            changeHandler("testRoomObj", selectedOption ? selectedOption : null)
                         } }
                        fullWidth
                        renderInput={(params) => 
                            <TextField {...params}
                                variant="outlined" 
                                label="Room"
                                required
                            />
                        }
                    />
                </Grid>

                {/* Confirm Schedule Button */}
                <Grid item lg={12} sm={12} style={{display:'flex',justifyContent:'center', marginTop: '10px'}}>
                    <Button 
                        style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 
                        onClick={ () => setOpenConfirmSubmission(true) }
                    >
                        Confirm Schedule
                    </Button>
                </Grid>

            </Grid>
            

            {/* Confirm Schedule Submission Dialog */}
            <Dialog open={openConfirmSubmission} onClose={() => setOpenConfirmSubmission(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm your selected test schedule by hitting the 'Confirm' button. Warning! This cannot be undone.
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmSubmission(false)}>Cancel</Button>
                        <Button onClick={saveApplicantTestScheduleHandler}>Confirm</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

          

        </div>
    );
};

export default TestScheduleSelector;