
import axios from 'axios';

import {dispatch} from './index';
import {CONFIG} from '../../AppConfig';

const URL = CONFIG.URL_API;


export const resetTestSchedule = (prop) => {
    switch(prop) {
        case 'prefCampus':
            dispatch("PREF_COURSE_SET", null);
            dispatch("PREF_MAJOR_SET", null);
            return;
        default:
            return true
    }
}

export const getPrefCampuses = (data) => {
    let payload = {};
    axios.get(
        `${URL}/admission/getPrefCampuses`,
        data
    ).then ( (response) => {
        payload = response.data;
        dispatch("PREF_CAMPUSES_SET", payload);
    })

}


export const getPrefCourses = (data) => {
    let payload = {};
    const { termID,testCenterID } = data;

    axios.get(
        `${URL}/admission/getCoursesOffered`,
        data
    ).then ( (response) => {
        payload = response.data;
        dispatch("PREF_COURSES_SET", payload);
    })

}

export const getPrefMajors = (data) => {
    let payload = {};
    const { termID,testCenterID,testDateID } = data;

    axios.get(
        `${URL}/admission/getCourseMajorsOffered`,
        data
    ).then( (response) => {
        payload = response.data;
        dispatch("PREF_MAJORS_SET", payload);
    })

}

export const getActiveTermID = () => {
    let payload = {};
    axios.get(
        `${URL}/admission/getActiveTermID/`
    ).then( (response) => {
        payload = response.data.activeTermID;
        
        dispatch("ACTIVE_TERM_ID_SET", payload);
    })
}

export const saveApplicantChoice1Preferrence = (data, onSuccess, onFailure) => {
    axios.post(
        `${URL}/admission/saveApplicantChoice1Preferrence`,
        data
    ).then( (response) => {
        if(response.data.status){
            onSuccess({...response.data});
        }else{
            onFailure({...response.data});
        }
        
    }).catch( (err) => {
        console.log(`SAVE APPLICANT TEST SCHEDULE: ${err}`)
        onFailure();
    })
}