import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import { 
    Table,
    Card,
    CardHeader,
    CardContent,
    Grid,
    TextField,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import {
    getPrefCampuses,
    getActiveTermID,
    getPrefCourses,
    getPrefMajors,
    saveApplicantChoice1Preferrence,
}from '../../redux/actions/admissionProgram';

const ProgramSelector = (props) => {

    let history = useHistory();

    const admissionProgram = useSelector(state => state.admissionProgram);
    const ACTIVE_TERM_ID = admissionProgram.activeTermID;

    const initPreferredCourseDetails = {

        campusID: -1,
        CampusObj: null,
        CourseID: null,
        CourseObj: null,
        MajorID: null,
        MajorObj: null
    }
    // const formInputRef = useRef();


    const [PreferredCourseDetails, setPreferredCourseDetails] = useState(initPreferredCourseDetails);
    const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false);
    const [openConfirmSubmission, setOpenConfirmSubmission] = useState(false);





    const [campusError, setCampusError] = useState(false);
    const [courseError, setCourseError] = useState(false);
    const [majorError, setMajorError] = useState(false);

    const validationHandler = () => {
        // if (!PreferredCourseDetails.CourseObj) {
        //     setCourseError(true);
        // } 
        
        // if (!PreferredCourseDetails.CampusObj) {
        //     setCampusError(true);
        // }

        // if (!PreferredCourseDetails.MajorObj) {
        //     setMajorError(true);
        // }

        // if(PreferredCourseDetails.CourseObj && PreferredCourseDetails.MajorObj){
        //     console.log('PreferredCourseDetails.CourseObj', PreferredCourseDetails.CourseObj);
        //     console.log('PreferredCourseDetails.MajorObj.MajorID', PreferredCourseDetails.MajorObj.MajorID);
        // }

        // if (PreferredCourseDetails.CourseObj && PreferredCourseDetails.CourseObj.MajorID !== 0){
        //     setMajorError(true);
        // }


    }; 

    // const handleSubmit = (event) => {
    //     event.preventDefault(); 
    
    
    //     if (PreferredCourseDetails.CourseObj && PreferredCourseDetails.CourseObj.MajorID !== 0) {
    //         if (!PreferredCourseDetails.MajorObj || PreferredCourseDetails.MajorObj.MajorID === 0) {
    //             setMajorError(true); 
    //             return; 
    //         }
    //     }
        
    // };




    const saveApplicantChoice1PreferrenceHandler = () => {
        const {CampusObj, CourseObj, MajorObj} = PreferredCourseDetails;
        const appNo = props.appNo ? props.appNo : ''; 

        const applicantPreferredCourseDetails = {
            appNo: appNo,
            prefCampusID: CampusObj.campusID,
            prefCourseID: CourseObj.ProgID,
            prefMajorID: MajorObj.MajorID,
            termID: ACTIVE_TERM_ID
        }

        // const onSuccess = () => {
        //     setPreferredCourseDetails(initPreferredCourseDetails);
        //     alert(`Submission Successful`);
        // }
        setOpenConfirmSubmission(false);

        const onFailure = () => {
            alert(`Submission Failed`);
        }

        saveApplicantChoice1Preferrence(applicantPreferredCourseDetails, props.onSubmitCoursePreferrenceSuccess, props.onSubmitCoursePreferrenceFailed );
        
    }

    const PRINT_STATE = () => {
        // console.log(`ADMISSION_TEST_USEEFFECT: ${JSON.stringify(admissionProgram)}`);
        // console.log(`TEST_SCHEDULE_DETAILS_USEEFFECT: ${JSON.stringify(PreferredCourseDetails)}`);
    }


    // FUNCTION: Handles changes in the test scheduling input fields
    const changeHandler = (prop, value) => {

        console.log(`prop: ${prop}, value: ${value}`);
        console.log('PreferredCourseDetails', PreferredCourseDetails);

        setPreferredCourseDetails({
            ...PreferredCourseDetails,
            [prop]: value
        })

    }


    // HOOK: Calls relevant functions on first page load
    useEffect( () => {
        getPrefCampuses();
        getActiveTermID();

        if(admissionProgram.prefCampuses && props.appCampusID && props.appCourseID){
            const defaultCampusObj = admissionProgram.prefCampuses.find( (prefCampus) => prefCampus.campusID === props.appCampusID);
            const defaultCourseObj = admissionProgram.prefCourses.find( (prefCourse) => prefCourse.ProgID === props.appCourseID);
            setPreferredCourseDetails({
                ...PreferredCourseDetails,
                CampusObj: defaultCampusObj
            })
        }  

    }, []);

    // HOOK: Sets default Campus based on the applicant's Choice1 Campus id
    useEffect( () => {
        
        if(admissionProgram.prefCampuses && props.appCampusID ){
            const defaultCampusObj = admissionProgram.prefCampuses.find( (prefCampus) => prefCampus.campusID === props.appCampusID);
            setPreferredCourseDetails({
                ...PreferredCourseDetails,
                CampusObj: defaultCampusObj,
            })
        }  
    }, [admissionProgram.prefCampuses])

    
    // HOOK: Sets default Course based on the applicant's Choice1 Course id
    // useEffect( () => {
        
    //     if(admissionProgram.prefCourses && props.appCourseID){
    //         const defaultCourseObj = admissionProgram.prefCourses.find( (prefCourse) => prefCourse.ProgID === props.appCourseID);
    //         setPreferredCourseDetails({
    //             ...PreferredCourseDetails,
    //             CourseObj: defaultCourseObj
    //         })
    //     }  
    // }, [admissionProgram.prefCourses])


    // HOOK: Sets default Major based on the applicant's Choice1 Major  id
    // useEffect( () => {
    
    //     if(admissionProgram.prefMajors && props.appMajorID){
    //         const defaultMajorObj = admissionProgram.prefMajors.find( (prefMajor) => prefMajor.MajorID === props.appMajorID);
    //         setPreferredCourseDetails({
    //             ...PreferredCourseDetails,
    //             MajorObj: defaultMajorObj
    //         })
    //     }  
    // }, [admissionProgram.prefMajors])

    // HOOK: Calls getPrefCourses() action when the selected testCenterID is changed
    useEffect( () => { 
        if(admissionProgram.activeTermID){
            getPrefCourses(
                {
                    termID: admissionProgram.activeTermID, 
                    testCenterID: PreferredCourseDetails.CampusObj ? PreferredCourseDetails.CampusObj.id : -1              
                }
            );
        }
        changeHandler('CourseObj', null);
        PRINT_STATE();
    }, [PreferredCourseDetails.CampusObj])

    // HOOK: Calls getPrefMajors() action when the selected CourseID is changed
    useEffect( () => { 
        if(admissionProgram.activeTermID){
            getPrefMajors(
                {
                    termID: admissionProgram.activeTermID, 
                    testCenterID: PreferredCourseDetails.CampusObj ? PreferredCourseDetails.CampusObj.id : -1,
                    testDateID: PreferredCourseDetails.CourseObj ? PreferredCourseDetails.CourseObj.progID : -1,
                }
            );
        } 
        changeHandler('MajorObj', null);
        PRINT_STATE();
    }, [PreferredCourseDetails.CourseObj])
    
    return (
        <div>
            <Grid item container lg={12} sm={12} style={{width:'80%',margin:'0 auto', marginTop: '30px'}}>

                <Typography style={{fontSize:'1.15rem',fontWeight:'bold'}}>
                    CHANGE FIRST COURSE PREFERRENCE
                </Typography>
                {/* Pref Campus Input */}
                <Grid item lg={12} sm={12} style={{paddingTop:'10px'}}>
                    <Autocomplete 
                        name="campusID"
                        value={PreferredCourseDetails.CampusObj}
                        options={admissionProgram.prefCampuses}
                        getOptionLabel={(option) => option.testCenterName}
                        onChange={(e, selectedOption) => { 
                            changeHandler("CampusObj", selectedOption ? selectedOption : null); 
                            setCampusError(false); 
                        } }
                        onBlur={validationHandler} 
                        fullWidth
                        renderInput={(params) => 
                            <TextField {...params}
                                variant="outlined" 
                                label="Campus"
                                required
                                error={campusError}
                                helperText={campusError ? "Campus is Required" : ""}
                            />
                        }
                    />
                </Grid>
       
                {/* Pref Course Input */}
                <Grid item lg={12} sm={12} style={{paddingTop:'10px'}}>
                    <Autocomplete 
                        name="CourseID"
                        value={PreferredCourseDetails.CourseObj}
                        options={admissionProgram.prefCourses && Array.isArray(admissionProgram.prefCourses) 
                            ? admissionProgram.prefCourses.filter(course => course.CampusID === (PreferredCourseDetails.CampusObj ? PreferredCourseDetails.CampusObj.campusID : null)
                            ) 
                            : []}
                        getOptionLabel={(option) => option.ProgName}
                        onChange={(e, selectedOption) => { 
                            changeHandler("CourseObj", selectedOption ? selectedOption : null) ;
                            setCourseError(false); 
                        }}
                        onBlur={validationHandler} 
                        fullWidth
                        renderInput={(params) => 
                            <TextField {...params}
                                variant="outlined" 
                                label="Course"
                                required
                                error={courseError}
                                helperText={courseError ? "Course is Required" : ""}
                            />
                        }
                    />
                </Grid>

                <Grid item lg={12} sm={12} style={{paddingTop: '10px'}}>
                    <Autocomplete
                        name="MajorID"
                        value={PreferredCourseDetails.MajorObj}
                        
                        // options={admissionProgram.prefMajors && Array.isArray(admissionProgram.prefMajors) 
                        //     ? admissionProgram.prefMajors.filter(major => 
                        //         major.MajorID !== 0 && major.ProgID === (PreferredCourseDetails.CourseObj ? PreferredCourseDetails.CourseObj.ProgID : null)
                        //     ) 
                        //     : null}

                        options={admissionProgram.prefMajors && Array.isArray(admissionProgram.prefMajors)
                            ? (() => {
                                const filteredMajors = admissionProgram.prefMajors.filter(major => 
                                    major.MajorID !== 0 && major.ProgID === (PreferredCourseDetails.CourseObj ? PreferredCourseDetails.CourseObj.ProgID : null)
                                );
                            
                                return filteredMajors.length > 0 ? filteredMajors : [{ Major: "No Major", MajorID: 0 }];
                                })()
                            : [{ Major: "No Major", MajorID: 0 }]} 

                        getOptionLabel={(option) => option.Major}
                        // getOptionDisabled={(option) => option.MajorID === 0}
                        onChange={(e, selectedOption) => {
                            changeHandler("MajorObj", selectedOption ? selectedOption : null);
                            setMajorError(false); 
                        }}
                        onBlur={validationHandler} 
                        fullWidth
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                variant="outlined"
                                label="Major"
                                // required={PreferredCourseDetails.CourseObj && PreferredCourseDetails.CourseObj.MajorID !== 0} 
                                required
                                error={majorError}
                                helperText={majorError ? "Major is Required" : ""}
                            />
                        }
                    />
                </Grid>


                {/* Confirm Preferred Course Button */}
                <Grid item lg={12} sm={12} style={{display:'flex',justifyContent:'center', marginTop: '10px'}}>
                    <Button 
                        style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}} 

                        onClick={() => {
                            if (!PreferredCourseDetails.CampusObj){
                                setCampusError(true);
                                return;
                            }
                            
                            if (!PreferredCourseDetails.CourseObj){
                                setCourseError(true);
                                return;
                            }
                            
                            if (!PreferredCourseDetails.MajorObj) {
                                setMajorError(true);
                                return;
                            } 
                            else{

                                setOpenConfirmSubmission(true)
                            }
                             
                        }}
                    >
                        Change Preference
                    </Button>
                </Grid>

            </Grid>


            

            {/* Confirm Preferred Course  Submission Dialog */}
            <Dialog open={openConfirmSubmission} onClose={() => setOpenConfirmSubmission(false)}>
                <DialogTitle></DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please confirm your selected course preferrence by hitting the 'Confirm' button. Warning! This cannot be undone.
                    </DialogContentText>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmSubmission(false)}>Cancel</Button>
                        <Button onClick={saveApplicantChoice1PreferrenceHandler}>Confirm</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>

          

        </div>
    );
};

export default ProgramSelector;