import applicant from './applicant';
import admissionTest from './admissionTest';
import admissionProgram from './admissionProgram';
import {combineReducers} from 'redux';


const rootReducer = combineReducers({
    applicant,
    admissionProgram,
    admissionTest
})

export default rootReducer;