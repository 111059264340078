import React from "react";
import {Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View,} from "@react-pdf/renderer";
import ViewPDF from "../ViewPDF";
import {Button,Typography} from "@material-ui/core";
// import NOAPHeader from "../static/img/NOAPHeader.png";
import NOAPHeader from "../static/img/USePATHeader.png";
import NOAPHeaderNew from "../static/img/USePATHeaderNew.png";
import NOAPFooter from "../static/img/NOAPFooter.png";
import NOAPFooterNew from "../static/img/NOAPFooterNew.png";
import eSig from "../static/img/eSigg.png";

// Create styles
const styles = StyleSheet.create({
   page: {
      padding: 0,
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
      fontFamily: "Times-Roman",
      fontSize: 12,
   },
   body: {
      paddingHorizontal: 70,
   },
   footer: {},
   borderBottom: {
      borderColor: "black",
      borderStyle: "solid",
      borderWidth: 1,
   },
   row: {
      flexDirection: "row"
   },
   image: {
      position: 'absolute',
      width: 92,
      height: 122,
      top: 40,
      right: 40
   },
   header: {
      position: 'absolute',
      width: '100%',
      top: 0,
      right: 0
   },
   boldText: {
      fontFamily: "Times-Bold"
   },
   horizontalDivider: {
      borderBottom:  1,
      borderColor: "black",
      borderStyle: "solid",
      borderWidth: 1,
      width: '100%'
   }
});

// Create Document Component
export default function USePATPermit({data}) {

   console.log(`PASSPORT_PHOTO_IN_USEPAT_PERMIT: ${data.passportPhoto}`)

   return (
      <Document
         title={"Permit to Take the Undergraduate Admission Test"}
         author={"University of Southeastern Philippines"}
      >
         <Page size="A4" style={styles.page}>
            <View>
               <Image src={NOAPHeaderNew} />
               <Image 
                  src={data.passportPhoto}
                  style={styles.image}
               />
               <View style={styles.body}>
   
                  <View style={{width: 110, marginBottom: 24, marginLeft: 380}}>
                     <View style={styles.borderBottom}>
                        <Text style={{textAlign: "center",fontWeight: "bold"}}>
                           {data.appNo}
                        </Text>
                     </View>
                     <Text style={{textAlign: "center", fontSize: 10}}>Registration Code</Text>
                  </View>
           
                  <View style={{marginBottom: 18}}>
                     <View style={{paddingHorizontal: 0}}>
                        <View >
                           <View style={{width: '100%'}}>
                              <Text>
                                 Name: <Text style={styles.boldText}>{data.name}</Text>
                              </Text>
                           </View>
                           <View style={{width: '100%'}}>
                              <Text>
                                 School Last Attended: <Text style={{textDecoration: 'underline'}}>{data.schoolLastAttended}</Text>
                              </Text>
                           </View>
                           <View style={{width: '100%'}}>
                              <Text>
                                 Preferred Course: <Text style={{textDecoration: 'underline'}}>{data.program}</Text>
                              </Text>
                           </View>
                        </View>
                        
                        <View style={{paddingHorizontal: 20, marginTop: 10}}>
                           <View style={{display: 'flex', flexDirection: 'row'}}>
                              <View style={{width: '60%'}}>
                                 <Text>
                                    Date of Exam: <Text style={{textDecoration: 'underline'}}>{data.testDate}</Text>
                                 </Text>
                              </View>
                              <View style={{width: '40%'}}>
                                 <Text>
                                    Time: <Text style={{textDecoration: 'underline'}}>{data.testTime}</Text>
                                 </Text>
                              </View>
                           </View>

                           <View style={{display: 'flex', flexDirection: 'row'}}>
                              <View style={{width: '60%'}}>
                                 <Text>
                                    Exam Venue: <Text style={{textDecoration: 'underline'}}>{data.testCenter}</Text>
                                 </Text>
                              </View>
                              <View style={{width: '40%'}}>
                                 <Text>
                                    Room: <Text style={{textDecoration: 'underline'}}>{data.testRoom}</Text>
                                 </Text>
                              </View>
                           </View>
                        </View>

                     </View>
                  </View>

                  <View style={styles.horizontalDivider}>

                  </View>

                  <View style={{marginBottom: 24}}>
                     <View>
                        <Text style={styles.boldText}>INSTRUCTIONS:</Text>
                     </View>
                     <View style={{marginLeft:'15px'}}>
                        <Text>
                           1. No examination permit, no test.
                        </Text>
                        <Text>
                           2. Please be at the testing site 15 minutes before the test.
                        </Text>
                        <Text>
                           3. LATE EXAMINEES will not be given the test.
                        </Text>
                        <Text>
                           4. Must be in proper/appropriate attire.
                        </Text>
                        <Text>
                           5. BRING: Dark-shaded pencil; ballpen; this exam permit and valid ID.
                        </Text>
                     </View>
                  </View>

                  <View style={{marginBottom: 24}}>
                     <View>
                        <Text style={styles.boldText}>IMPORTANT NOTICE:</Text>
                     </View>
                     <View style={{marginLeft:'15px'}}>
                        <Text>
                           a. Applicant should take the examination test only once.
                        </Text>
                        <Text>
                           b. Release of the individual results will be announced through the USeP official website and Facebook page.
                        </Text>
                        <Text>
                           c. View your exam results at <Text style={{textDecoration: 'underline'}}>https://admissionresult.usep.edu.ph</Text> using the registration code assigned by the system
                              during the filing of applications for admission.
                        </Text>
                     </View>
                  </View>

               </View>
            </View>
            <Image src={NOAPFooterNew} style={styles.footer}/>
         </Page>
      </Document>
   );
}

export function ViewNOAP({data}) {
   return <ViewPDF documentComponent={<USePATPermit data={data}/>}/>;
}

export function DownloadUSePATPermit({fileName, data}) {
   return (
      <PDFDownloadLink document={<USePATPermit data={data}/>} fileName={fileName || "USePATPermit.pdf"}>
         {({blob, url, loading, error}) => {
            console.log({blob, url, loading, error});

            // if (url) {
            //   const link = document.createElement("a");
            //   link.download = fileName;
            //   link.href = url;
            //   document.body.appendChild(link);
            //   link.click();
            //   document.body.removeChild(link);
            //   // if (isFunction(afterDownloaded)) afterDownloaded();
            // }

            return <Button style={{borderRadius:"1000px",background:"#d75d5f",color:"white",filter:"drop-shadow(0px 12px 16px rgba(220, 113, 113, 0.5))"}}  variant="outlined" color="primary">
                  <Typography style={{textDecoration:"none"}}>Download Permit to Take the USePAT</Typography>
               </Button>;
         }}
      </PDFDownloadLink>
   );
}
